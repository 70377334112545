import { Typography, Button, Dialog, DialogActions, Paper, IconButton, Icon, Avatar, Tooltip, TextField, Popover, Grid } from '@material-ui/core'
import React from 'react'
import Lottie from 'react-lottie'
import upload from 'assets/animations/upload.json'
import { useDropzone } from 'react-dropzone'
import { red } from '@material-ui/core/colors'
import { ISupplierFile, IUploadDocumentFile } from 'types'
import FileSaver from 'file-saver'
import { deleteSupplierFileByIdService, getSupplierFileByIdService } from 'lib'

interface Props {
    open: boolean
    onClose: () => void
    onAccept?: (files: IUploadDocumentFile[]) => void
    singleFile?: boolean
    hideDescription?: boolean
    supplierUploadedFiles?: ISupplierFile[]
    onSuccessfulDelete?: () => void
    validateisExel?: boolean
}

const FileUploadDialog = (props: Props) => {
    const { open, onClose, onAccept, singleFile, hideDescription, supplierUploadedFiles, onSuccessfulDelete, validateisExel } = props
    const [files, setFiles] = React.useState<IUploadDocumentFile[] | undefined>()
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
    const [selectedFile, setSelectedFile] = React.useState<ISupplierFile | undefined>()
    const onDrop = React.useCallback(acceptedFiles => {
        const newFiles = files ?? []
        setFiles(newFiles.concat(acceptedFiles.map(f => ({ file: f, description: "" }))))
    }, [files])

    const validateExelTaxSettings = {
        onDrop,
        accept: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'], // solo archivos .xlsx
        multiple: false,
    }
    const { getRootProps, getInputProps, isDragActive, open: handleOpenUploader } = useDropzone(
        validateisExel ? validateExelTaxSettings : { onDrop })

    const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>, supplierFile: ISupplierFile) => {
        setAnchorEl(event.currentTarget)
        setSelectedFile(supplierFile)
    }
    const handlePopoverClose = () => {
        setAnchorEl(null)
        setSelectedFile(undefined)
    }
    const handleDelete = (file: IUploadDocumentFile) => {
        if (files) {
            if (files.length === 1) {
                setFiles(undefined)
            } else {
                setFiles(current => current?.filter(f => f !== file))
            }
        }
    }
    const handlePermanentDelete = async () => {
        try {
            await deleteSupplierFileByIdService(selectedFile?.supplierFileId ?? -1)
            handlePopoverClose()
            if (onSuccessfulDelete) {
                onSuccessfulDelete()
            }
        } catch (error) {
            console.log(error)
        }
    }
    const handleCancel = () => {
        onClose()
        setFiles(undefined)
    }
    const handleAccept = () => {
        if (onAccept && files) {
            onAccept(files)
        }
        handleCancel()
    }
    const handleDownload = async (supplierFile?: ISupplierFile) => {
        if (supplierFile) {
            const result = await getSupplierFileByIdService(supplierFile.supplierFileId ?? -1)
            FileSaver.saveAs(result, supplierFile.fileName ?? supplierFile.supplierFileId?.toString())
        }
    }
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <div className="px-4 py-3">
                <Typography
                    variant="h6"
                    color="textSecondary"
                    align="center"
                    style={{ fontWeight: 600, letterSpacing: 0.2 }}
                >
                    {(!files || files?.length === 1 || singleFile) ? "Archivo por subir" : "Archivos por subir"}
                </Typography>
                <input multiple={!singleFile} {...getInputProps()} />
                {
                    !files ?
                        <div className={`flex flex-col justify-center items-center m-5 p-4 cursor-pointer file-upload-container`} {...getRootProps()}>
                            <Lottie
                                options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: upload,
                                    rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
                                }}
                                height={200}
                                width={300}
                            />
                            {
                                isDragActive ?
                                    <Typography variant="caption" align="center">{"Suelta aquí los archivos"}</Typography> :
                                    <React.Fragment>
                                        <Typography variant="caption" align="center">{"Arrastra y suelta aquí los archivos"}</Typography>
                                        <Typography className="pt-2 pb-4" variant="caption" align="center">{"ó"}</Typography>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            size="small"
                                            disableElevation
                                        >
                                            {singleFile ? "Subir archivo" : "Subir archivos"}
                                        </Button>
                                    </React.Fragment>
                            }
                        </div>
                        :
                        <div className="my-6 p-2 overflow-y-auto" style={{ height: files ? "auto" : 220, maxHeight: 350 }}>
                            {
                                files.map((upload, index) => (
                                    <Paper key={`upload` + index} style={{ width: 380 }} className="w-full p-3 flex items-center mb-3">
                                        <Avatar style={{ width: 30, height: 30, fontSize: "0.6em", backgroundColor: red[400] }}>
                                            {upload.file?.name.split(".").pop()?.toUpperCase()}
                                        </Avatar>
                                        <div className="flex flex-grow flex-col ml-4 truncate pr-4">
                                            <Typography className="truncate" variant="caption">
                                                {upload.file?.name.split(".").shift()}
                                            </Typography>
                                            {
                                                !hideDescription &&
                                                <TextField
                                                    size="small"
                                                    placeholder="Descripción..."
                                                    fullWidth
                                                    value={upload.description}
                                                    onChange={(e) => setFiles(current => {
                                                        if (current) {
                                                            const newValues = [...current]
                                                            newValues[index].description = e.target.value
                                                            return newValues
                                                        }
                                                        return undefined
                                                    })}
                                                    style={{ marginTop: 4 }}
                                                    classes={{ root: "file-description-input" }}
                                                />
                                            }
                                        </div>
                                        <Tooltip arrow title="Eliminar archivo">
                                            <IconButton onClick={() => handleDelete(upload)} size="small">
                                                <Icon fontSize="small">close</Icon>
                                            </IconButton>
                                        </Tooltip>
                                    </Paper>
                                ))
                            }
                        </div>
                }
                <div className="mt-3 px-2 py-1 overflow-y-auto" style={{ maxHeight: 350 }}>
                    {
                        (supplierUploadedFiles && supplierUploadedFiles?.length > 0) &&
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            align="center"
                            style={{ fontWeight: 600, letterSpacing: 0.2, paddingBottom: 10 }}
                        >
                            {singleFile ? "Archivo subido" : "Archivos subidos"}
                        </Typography>
                    }
                    {
                        supplierUploadedFiles && supplierUploadedFiles.map((existingFile, index) => (
                            <Paper key={`existingFile` + index} style={{ width: 380 }} className="w-full p-3 flex items-center mb-3">
                                <Avatar style={{ width: 30, height: 30, fontSize: "0.6em", backgroundColor: red[400] }}>
                                    {existingFile.file?.name.split(".").pop()?.toUpperCase()}
                                </Avatar>
                                <div className="flex flex-grow flex-col ml-4 truncate pr-4">
                                    <Typography className="truncate" variant="caption">
                                        {existingFile.file?.name.split(".").shift()}
                                    </Typography>
                                </div>
                                <Tooltip arrow title="Descargar archivo">
                                    <IconButton onClick={() => handleDownload(existingFile)} size="small">
                                        <Icon fontSize="small">download</Icon>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip arrow title="Eliminar archivo permanentemente">
                                    <IconButton onClick={(event) => handlePopoverClick(event, existingFile)} size="small">
                                        <Icon fontSize="small">delete</Icon>
                                    </IconButton>
                                </Tooltip>
                                <Popover
                                    open={Boolean(selectedFile)}
                                    anchorEl={anchorEl}
                                    onClose={handlePopoverClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }}
                                >
                                    <Grid container justifyContent="center" style={{ padding: 10, maxWidth: 320 }}>
                                        <Typography variant="body2" style={{ maxWidth: 300, textAlign: "center", paddingBottom: 10 }}>
                                            ¿Está seguro que desea borrar este archivo permanentemente?
                                        </Typography>
                                        <Grid container item justifyContent="space-around" style={{ width: "50%" }} >
                                            <Button
                                                onClick={handlePermanentDelete}
                                                color="primary" variant="contained" size="small"
                                            >
                                                SI
                                            </Button>
                                            <Button
                                                onClick={handlePopoverClose}
                                                size="small"
                                            >
                                                NO
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Popover>
                            </Paper>
                        ))
                    }
                </div>
            </div>
            <DialogActions>
                <Button
                    size="small"
                    onClick={handleCancel}
                >
                    {"Cancelar"}
                </Button>
                {
                    files && files.length > 0 && !singleFile &&
                    <Button
                        size="small"
                        onClick={handleOpenUploader}
                        style={{ marginLeft: 20 }}
                    >
                        {"Subir mas archivos"}
                    </Button>
                }
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    disableElevation
                    style={{ marginLeft: 20 }}
                    disabled={!Boolean(files)}
                    onClick={handleAccept}
                >
                    {"Aceptar"}
                </Button>
            </DialogActions>
        </Dialog >
    )
}

export default FileUploadDialog
