import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { FIELDS } from 'lib'
import { DOCUMENTS_API_SERVICE_DOMAIN } from 'lib/constants/api'
import { PATH_MASS, PATH_RETENTION, PATH_UPLOAD, PATH_VENSECAR } from '../../constants/paths'

const intance = axios.create({ baseURL: DOCUMENTS_API_SERVICE_DOMAIN })

const formDataConfig: AxiosRequestConfig = {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
}

export const uploadExcelFile = async (file: any) => {
    const formData = new FormData()
    formData.append(FIELDS.file.key, file)
    try {
        const result = await intance.post(PATH_VENSECAR + PATH_RETENTION + PATH_MASS + PATH_UPLOAD, formData, formDataConfig)
        return result.status
    } catch (error: any) {
        const e: AxiosError = error
        return e.response?.status || 0
    }
}


export const uploadExcelFilesConciliationService = async (file1: File, file2:File) => {
    const formData = new FormData()
  formData.append('coupaFile', file1)
  formData.append('s21File', file2)
    try {
        const result = await intance.post('/upload/mass/files', formData, formDataConfig)
        return result
    } catch (error: any) {
      if (error.response) {
            throw error.response.data
        } else {
            throw new Error("Error al momento de subir los archivos")
        }
    }
}



