import { Box, Button, CircularProgress, Icon, IconButton, Paper, Snackbar, Tooltip, Typography } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"
import { FileUploadDialog } from "components"
import { ROOT_TITLE } from "lib"
import { uploadExcelFilesConciliationService } from "lib/utils/api/excelfile"
import React from "react"
import { Helmet } from "react-helmet"
import { IMessageConfig } from "types"

type TypeFile = 'file1' | 'file2' | null

const ExcelRetentionPage = () => {

    const [messageConfig, setMessageConfig] = React.useState<IMessageConfig>({ open: false, message: "", severity: "info" })
    const [file1, setFile1] = React.useState<File | null>(null)
    const [file2, setFile2] = React.useState<File | null>(null)
    const [open, setOpen] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState<boolean>(false)
    const [typeFile, setTypeFile] = React.useState<TypeFile>(null)

    const existFileOne = React.useMemo(() => {
        return Boolean(file1)
    }, [file1])

    const existFileTwo = React.useMemo(() => {
        return Boolean(file2)
    }, [file2])


    const handleDeleteFile = (typeFile: TypeFile) => {
        if (typeFile === 'file1') {
            setFile1(null)
        }
        if (typeFile === 'file2') {
            setFile2(null)
        }
    }


    const handleUploadFiles = async () => {
        try {
            setLoading(true)
            if (file1 === null || file2 === null) {
                setMessageConfig({ open: true, message: "Debe subir los 2 archivos", severity: "error" })
                return
            }
            const response = await uploadExcelFilesConciliationService(file1, file2)
            if (response.status === 200) {
                setMessageConfig({ open: true, message: response.data, severity: "success" })
            }
            setLoading(false)
        } catch (error: any) {
            setLoading(false)
            setMessageConfig({ open: true, message: error, severity: "error" })
        }
    }

    const handleUpload = async (file: File) => {
        try {
            if (typeFile === 'file1') {
                const file1 = file
                setFile1(file1)
            } else if (typeFile === 'file2') {
                const file2 = file
                setFile2(file2)
            }
        } catch (error) {
            console.log("Error", error)
        }
    }

    return (
        <Paper className="flex flex-col h-full overflow-hidden p-4 pb-0">
            <Helmet>
                <title>{`${ROOT_TITLE} - Carga Exels`}</title>
            </Helmet>
            <Box className="ml-1.5 pt-2">

                <Alert
                    severity="warning"
                    icon={<Icon fontSize='inherit'>warning</Icon>}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: '1rem 0',
                        width: 550,
                        padding: '0.75rem 1rem',
                    }}
                >
                    <AlertTitle>Nota</AlertTitle>
                    Se debe subir los dos archivos Excel de forma obligatoria, y en el
                    orden correspondiente.
                </Alert>

                <Box style={{ display: 'flex', gap: 7 }}>

                    {!existFileOne && (
                        <Button
                            disableElevation
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={() => {
                                setTypeFile('file1')
                                setOpen(true)
                            }}
                            style={{ paddingLeft: "0.85rem", height: 35, width: 270 }}
                        >
                            {"Subir excel CoupaFile"}
                            <Icon fontSize="small" className="ml-1">
                                upload
                            </Icon>
                        </Button>
                    )}

                    {!existFileTwo && (
                        <Button
                            disableElevation
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={() => {
                                setTypeFile('file2')
                                setOpen(true)
                            }}
                            style={{ paddingLeft: "0.85rem", height: 35, width: 270 }}
                        >
                            {"Subir excel S21File"}
                            <Icon fontSize="small" className="ml-1">
                                upload
                            </Icon>
                        </Button>
                    )}
                </Box>


                <Box style={{
                    display: 'flex',
                    gap: 7,
                    paddingTop: 20,
                    width: 550,
                    flexDirection: 'column'
                }}>
                    {existFileOne && (
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: 'space-between',
                                padding: "0.5rem 1rem",
                                border: "1px solid #ccc",
                                borderRadius: "8px",
                                backgroundColor: "#f5f5f5",
                                boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
                            }}
                        >
                            <Typography
                                variant="body2"
                                style={{
                                    marginRight: "0.75rem",
                                    color: "#333",
                                }}
                            >
                                <strong>Archivo CoupaFile seleccionado:</strong> {file1!.name}
                            </Typography>
                            <IconButton
                                size="small"
                                onClick={() => handleDeleteFile('file1')}
                                style={{ color: "#ff6b6b" }}
                            >
                                <Tooltip title="Borrar Archivo">
                                    <Icon fontSize="small">close</Icon>
                                </Tooltip>
                            </IconButton>
                        </Box>
                    )}

                    {existFileTwo && (
                        <Box
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: 'space-between',
                                padding: "0.5rem 1rem",
                                border: "1px solid #ccc",
                                borderRadius: "8px",
                                backgroundColor: "#f5f5f5",
                                boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
                            }}
                        >
                            <Typography
                                variant="body2"
                                style={{
                                    marginRight: "0.75rem",
                                    color: "#333",
                                }}
                            >
                                <strong>Archivo S21File seleccionado:</strong> {file2!.name}
                            </Typography>
                            <IconButton
                                size="small"
                                onClick={() => handleDeleteFile('file2')}
                                style={{ color: "#ff6b6b" }}
                            >
                                <Tooltip title="Borrar Archivo">
                                    <Icon fontSize="small">close</Icon>
                                </Tooltip>
                            </IconButton>
                        </Box>
                    )}


                    <Box style={{ display: 'flex', flexDirection: 'column', paddingTop: 2 }} >
                        <Button
                            disabled={!(existFileOne && existFileTwo) || loading}
                            disableElevation
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={async () => {
                                await handleUploadFiles()
                            }}

                            style={{ paddingLeft: "0.85rem", height: 35, width: 550 }}
                        >
                            {loading ? <CircularProgress style={{ color: "white" }} size={15} className="my-1" thickness={8} /> : "Procesar Archivos"}
                            <Icon fontSize="small" className="ml-1">
                                upload
                            </Icon>
                        </Button>
                    </Box>

                </Box>

                <Snackbar open={messageConfig.open} autoHideDuration={6000} onClose={() => setMessageConfig({ ...messageConfig, open: false })} anchorOrigin={{ horizontal: "right", vertical: "bottom" }}>
                    <Alert variant="filled" onClose={() => setMessageConfig({ ...messageConfig, open: false })} severity={messageConfig.severity}>
                        {messageConfig.message}
                    </Alert>
                </Snackbar>

                <FileUploadDialog
                    open={open}
                    onClose={() => { setOpen(false) }}
                    onAccept={(files) => { handleUpload(files[0].file) }}
                    singleFile
                    hideDescription
                    validateisExel
                />

            </Box>
        </Paper>
    )
}

export default ExcelRetentionPage